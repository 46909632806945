<template>
  <div class="page">
    <Navbar :title="'vip'" />
    <div class="page_box">
      <swiper
        :modules="modules"
        class="mySwiper"
        @slideChange="levelChange"
        @swiper="onSwiper"
      >
        <swiper-slide
          v-for="(item, index) in levelList"
          :key="index"
          class="level_item"
        >
          <img :src="item.icon" alt="" />
        </swiper-slide>
      </swiper>
      <div class="vip_title">
        VIP<span>{{ level }}</span>
      </div>
      <div class="vip_scrollbar">
        <div
          class="vip_scrollbar_val"
          :style="`width: ${needExpScrollbar}%`"
        ></div>
      </div>
      <div class="vip_exp_text">
        <span v-if="needExp >= 0">距离下一等级还差： {{ needExp }}EXP</span>
        <span v-else>该等级已满级</span>
      </div>
      <div class="vip_explain">
        <div class="vip_explain_nav">
          VIP权益{{ levelList[level].lok.length }}/7
        </div>
        <div class="vip_explain_box">
          <div
            class="vip_explain_item"
            v-for="(item, index) in vipExplain"
            :key="index"
            @click="goVipPath(item)"
          >
            <div
              class="vip_explain_icon"
              :class="!item.colorShow ? 'vip_explain_icon_show' : ''"
            >
              <SvgIcon
                :name="item.svg"
                :color="item.colorShow ? '#c37c00' : '#8D8D8D'"
              />
            </div>
            <div
              :class="
                item.colorShow ? 'vip_explain_title' : 'vip_explain_title_show'
              "
            >
              {{ item.name }}
            </div>
            <div
              class="vip_explain_remark"
              v-if="item.colorShow && item.remark"
            >
              {{ item.remark }}
            </div>
          </div>
        </div>
      </div>
      <div class="vip_level_nav">成长等级</div>
      <img class="vip_level_img" src="@/assets/img/vip/level.png" alt="" />
      <div class="vip_level_dec">
        玩家每次充值后，会按照每1000VND=1经验值的形式发放相对应的经验值到玩家账户上，根据以上表格，获取到相应的经验值后便可达到相对应的VIP等级，玩家同时可以享有当前VIP等级的所有会员权益。
      </div>
      <div class="vip_level_dec">
        同时，以半年为一个周期，累计充值无法达到保险级经验的账户，也会扣除相应的经验值，存在降低VIP等级的情况，具体经验值情况如下表所示：
      </div>
      <div class="vip_level_table">
        <div class="vip_level_tr">
          <div class="vip_level_tb">VIP等级</div>
          <div class="vip_level_tb">扣除经验/半年</div>
          <div class="vip_level_tb">保级累充/半年</div>
        </div>
        <div class="vip_level_tr">
          <div class="vip_level_tb">Vip1</div>
          <div class="vip_level_tb">240</div>
          <div class="vip_level_tb">120</div>
        </div>
        <div class="vip_level_tr">
          <div class="vip_level_tb">Vip2</div>
          <div class="vip_level_tb">720</div>
          <div class="vip_level_tb">450</div>
        </div>
        <div class="vip_level_tr">
          <div class="vip_level_tb">Vip3</div>
          <div class="vip_level_tb">1494</div>
          <div class="vip_level_tb">990</div>
        </div>
        <div class="vip_level_tr">
          <div class="vip_level_tb">Vip4</div>
          <div class="vip_level_tb">2656</div>
          <div class="vip_level_tb">1760</div>
        </div>
        <div class="vip_level_tr">
          <div class="vip_level_tb">Vip5</div>
          <div class="vip_level_tb">5395</div>
          <div class="vip_level_tb">3575</div>
        </div>
        <div class="vip_level_tr">
          <div class="vip_level_tb">Vip6</div>
          <div class="vip_level_tb">12800</div>
          <div class="vip_level_tb">8000</div>
        </div>
        <div class="vip_level_tr">
          <div class="vip_level_tb">Vip7</div>
          <div class="vip_level_tb">28000</div>
          <div class="vip_level_tb">17500</div>
        </div>
        <div class="vip_level_tr">
          <div class="vip_level_tb">Vip8</div>
          <div class="vip_level_tb">52500</div>
          <div class="vip_level_tb">35000</div>
        </div>
        <div class="vip_level_tr">
          <div class="vip_level_tb">Vip9</div>
          <div class="vip_level_tb">140000</div>
          <div class="vip_level_tb">80000</div>
        </div>
        <div class="vip_level_tr">
          <div class="vip_level_tb">Vip10</div>
          <div class="vip_level_tb">420000</div>
          <div class="vip_level_tb">240000</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper.scss";
export default {
  components: { Swiper, SwiperSlide },
  data() {
    return {
      level: 0, // 当前等级
      exp: 0, // 当前经验
      needExp: 0, // 需要升级的经验
      needExpScrollbar: 0, //经验条长度
      modules: null,
      levelList: [
        // vip等级
        {
          level: 0,
          icon: require("@/assets/img/vip/v0.png"),
          exp: 0,
          lok: [0],
          tips2: "",
          tips4: "",
          tips5: "",
          tips7: "",
        },
        {
          level: 1,
          icon: require("@/assets/img/vip/v1.png"),
          exp: 300,
          lok: [0, 1, 2],
          tips2: "Nhận",
          tips4: "",
          tips5: "",
          tips7: "",
        },
        {
          level: 2,
          icon: require("@/assets/img/vip/v2.png"),
          exp: 900,
          lok: [0, 1, 2],
          tips2: "Nhận",
          tips4: "",
          tips5: "",
          tips7: "",
        },
        {
          level: 3,
          icon: require("@/assets/img/vip/v3.png"),
          exp: 1800,
          lok: [0, 1, 2],
          tips2: "Nhận",
          tips4: "",
          tips5: "",
          tips7: "",
        },
        {
          level: 4,
          icon: require("@/assets/img/vip/v4.png"),
          exp: 3200,
          lok: [0, 1, 2],
          tips2: "Nhận",
          tips4: "",
          tips5: "",
          tips7: "",
        },
        {
          level: 5,
          icon: require("@/assets/img/vip/v5.png"),
          exp: 6500,
          lok: [0, 1, 2, 3],
          tips2: "Nhận",
          tips4: "1%",
          tips5: "",
          tips7: "",
        },
        {
          level: 6,
          icon: require("@/assets/img/vip/v6.png"),
          exp: 16000,
          lok: [0, 1, 2, 3, 4],
          tips2: "Nhận",
          tips4: "1%",
          tips5: "1 lần",
          tips7: "",
        },
        {
          level: 7,
          icon: require("@/assets/img/vip/v7.png"),
          exp: 35000,
          lok: [0, 1, 2, 3, 4, 5],
          tips2: "Nhận",
          tips4: "1%",
          tips5: "1 lần",
          tips7: "",
        },
        {
          level: 8,
          icon: require("@/assets/img/vip/v8.png"),
          exp: 70000,
          lok: [0, 1, 2, 3, 4, 5, 6],
          tips2: "Nhận",
          tips4: "2%",
          tips5: "1 lần",
          tips7: "1 lần",
        },
        {
          level: 9,
          icon: require("@/assets/img/vip/v9.png"),
          exp: 200000,
          lok: [0, 1, 2, 3, 4, 5, 6],
          tips2: "Nhận",
          tips4: "2%",
          tips5: "1 lần",
          tips7: "2 lần",
        },
        {
          level: 10,
          icon: require("@/assets/img/vip/v10.png"),
          exp: 600000,
          lok: [0, 1, 2, 3, 4, 5, 6],
          tips2: "Nhận",
          tips4: "3%",
          tips5: "1 lần",
          tips7: "3 lần",
        },
      ],
      swiper: null,
      vipExplain: [
        // vip 权益
        {
          name: "签到积分",
          path: "vip/signin",
          svg: "qdjf",
          check: true,
          remark: "",
          colorShow: false,
        },
        {
          name: "VIP礼包",
          path: "vip/vipgift",
          svg: "lb",
          check: false,
          remark: "领取",
          colorShow: false,
        },
        {
          name: "VIP券",
          path: "vip/vipcoupon",
          svg: "yhq",
          check: false,
          remark: "",
          colorShow: false,
        },
        {
          name: "充值奖励",
          path: "vip/nap",
          svg: "czjl",
          check: false,
          remark: "3%",
          colorShow: false,
        },
        {
          name: "被盗找回",
          path: "vip/retrieve",
          svg: "bdzh",
          check: false,
          remark: "1次",
          colorShow: false,
        },
        {
          name: "遇见福利",
          path: "vip/meet",
          svg: "yjfl",
          check: false,
          remark: "",
          colorShow: false,
        },
        {
          name: "误操作恢复",
          path: "vip/recovery",
          svg: "wczhf",
          check: false,
          remark: "3次",
          colorShow: false,
        },
      ],
    };
  },
  created() {
    this.setScrollbar();
  },
  methods: {
    setScrollbar() {
      this.levelList.forEach((item, index) => {
        if (this.exp >= item.exp) {
          item.level < 10
            ? (this.level = item.level + 1)
            : (this.level = item.level);
        }
      });
      this.showScrollbar();
    },
    showScrollbar() {
      this.levelList[this.level].lok.forEach((item) => {
        this.vipExplain[item].colorShow = true;
      });
      if (this.level <= 10) {
        this.needExp = this.levelList[this.level].exp - this.exp;
        this.needExpScrollbar =
          (this.exp / this.levelList[this.level].exp).toFixed(2) * 100;
      }
    },
    onSwiper(e) {
      e.slideTo(this.level,false);
    },
    resetColorShow() {
      //将每个icon置空
      this.levelList[10].lok.forEach((item) => {
        this.vipExplain[item].colorShow = false;
      });
    },
    levelChange(e) {
      this.level = e.activeIndex;
      this.resetColorShow();
      this.showScrollbar();
      // console.log(this.Swiper);
      // console.log(e.activeIndex);
    },
    //获取Icon高亮
    goVipPath(item) {
      this.$router.push(item.path);
    },
  },
};
</script>
<style scoped lang="scss">
$vip-color: #c37c00;
.page_box {
  padding: 0 0.22rem 1rem 0.22rem;
}
.mySwiper {
  width: 100%;
  min-height: 2rem;
  height: 0.2rem;
  overflow: hidden;
}
.level_item {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 0.3rem;
  width: 100% !important;
  box-sizing: border-box;
}
.level_item img {
  width: 1.7rem;
}
.vip_title {
  font-weight: bold;
  font-size: 0.31rem;
  color: $vip-color;
}
.vip_scrollbar {
  margin: 0.15rem 0;
  width: 75%;
  height: 0.11rem;
  min-height: 0.11rem;
  background-color: #e1e1e1;
  border-radius: 1rem;
  overflow: hidden;
}
.vip_scrollbar_val {
  width: 0;
  height: 100%;
  background-color: $vip-color;
}
.vip_exp_text {
  font-size: 0.22rem;
  font-weight: bold;
}
.vip_explain {
  margin-top: 0.2rem;
  width: 100%;
  min-height: 4.2rem;
  background-color: #fff8eb;
  border-radius: 0.2rem;
  overflow: hidden;
}
.vip_explain_nav {
  width: 100%;
  min-height: 0.7rem;
  line-height: 0.7rem;
  text-align: center;
  background-color: #f0d6a5;
  color: #c37c00;
  font-size: 0.3rem;
  font-weight: bold;
}
.vip_explain_box {
  display: flex;
  flex-wrap: wrap;
  padding: 0.2rem;
}
.vip_explain_item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.2rem 0;
  width: 25%;
  position: relative;
}
.vip_explain_remark {
  position: absolute;
  top: -0.1rem;
  right: -0.2rem;
  width: 0.7rem;
  text-align: center;
  border-radius: 0.3rem 0.3rem 0.3rem 0;
  background-color: red;
  color: #fff;
}
.vip_explain_icon {
  padding: 0.15rem;
  width: 0.47rem;
  height: 0.47rem;
  min-height: 0.47rem;
  background-color: #f0d4a0;
  border-radius: 50%;
  overflow: hidden;
}
.vip_explain_icon_show {
  background-color: #e1e1e1;
}
.vip_explain_icon svg {
  width: 0.47rem;
  height: 0.47rem;
}
.vip_explain_title {
  margin-top: 0.1rem;
  font-size: 0.22rem;
  color: $vip-color;
}
.vip_explain_title_show {
  margin-top: 0.1rem;
  font-size: 0.22rem;
  color: #8d8d8d;
}
.vip_level_nav {
  margin: 0.4rem 0 0.1rem 0;
  padding: 0 0.3rem;
  line-height: 0.4rem;
  border: 1px solid $vip-color;
  color: $vip-color;
  border-radius: 1rem;
}
.vip_level_img {
  margin: 0.3rem 0;
}
.vip_level_dec {
  line-height: 0.4rem;
  font-size: 0.23rem;
}
.vip_level_table {
  margin-top: 0.3rem;
  width: 100%;
  border-left: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}
.vip_level_tr {
  display: flex;
}
.vip_level_tr:nth-child(1) {
  background-color: #fde2b0;
}
.vip_level_tr:nth-child(1) .vip_level_tb {
  height: 0.9rem;
  color: $vip-color;
}
.vip_level_tb {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 0.6rem;
  font-size: 0.23rem;
  border-top: 1px solid #ddd;
  border-right: 1px solid #ddd;
}
</style>